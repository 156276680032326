<template>
  <div>
    <tabbed-display
      :tabs="tabs"
      :data="data"
      @finished="finished"
      @quited="quited"
      v-if="loaded"
    ></tabbed-display>
    <loading v-else />
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import { Notification } from "@/helpers/notifications";
import store from "@/store";
import axios from "@/requests/axios";
import { execute_json } from "@/helpers/requests";

export default {
  data() {
    return {
      data: {
        employee: {
          value: null,
          fullname: null,
        },
        setup_type: {
          selected: null,
        },
        setup_note: {
          value: null,
        },
        quantity: {
          value: null,
          watchMin: 50,
          watchMax: 200,
          minMessage: "min!",
          maxMessage: "max!",
        },
      },
      loaded: false,
    };
  },
  created() {
    store.commit("bottomnav/disable");
    let promises = [];
    promises.push(store.dispatch("setup/loadSetup", {}));
    this.calculateLimitMessages();
    Promise.all(promises).then(() => {
      this.loaded = true;
      console.log("fully loaded..");
    });
  },
  methods: {
    finished() {
      let note = Notification.request({
        message: "OK'ing first piece..",
      });
      let json_data = {
        machine_id: this.machine_id,
      };
      if (this.needsEmployee) {
        json_data.employee = this.data.employee.value;
      }
      if (this.needsType) {
        json_data.setup_type = this.data.setup_type.selected.code;
      }
      if (this.needsNote) {
        json_data.setup_note = this.data.setup_note.value;
      }
      if (this.needsQuantity) {
        json_data.sf61_qty = this.data.quantity.value;
      }
      execute_json("first_piece_ok", json_data)
        .then(() => {
          note.ok({
            message: "First piece ok!",
          });
        })
        .catch((err) => {
          console.log("axios error", err);
          note.fail({
            message: err.statusText,
          });
        });
      this.$router.push(this.$route.path.replace("/first-piece-ok", ""));
    },
    quited() {
      this.$router.push(this.$route.path.replace("/first-piece-ok", ""));
    },
    calculateLimitMessages() {
      if (this.needsQuantity) {
        this.data.quantity.watchMin = this.cardGoodQty - this.maxScrapQty;
        this.data.quantity.minMessage =
          this.$t("1067") + " " + this.maxScrapQty+"\n"+this.$t('1274')+": "+(this.cardGoodQty - +this.data.quantity.value);
      }
       if (this.needsQuantity ) {
        this.data.quantity.watchMax = this.cardGoodQty;
        this.data.quantity.maxMessage = this.$t('4846')+' '+this.cardGoodQty;
       }
    },
  },
  computed: {
    ...mapState({
      bottomNav: (state) => state.bottomnav,
      notifications: (state) => state.notifications.index,
      setupTypes: (state) => state.setup.index,
    }),
    ...mapGetters({
      machineById: "machines/byId",
      configByMachine: "machines/configByMachine",
      machineHasSf: "machines/machineHasSf",
    }),
    machine_id() {
      return this.$route.params.machine;
    },
    machine() {
      return this.machineById(this.machine_id);
    },
    config() {
      return this.configByMachine(this.machine_id);
    },
    needsEmployee() {
      return this.machineHasSf(this.machine_id, "29");
    },
    needsNote() {
      return this.machineHasSf(this.machine_id, "23");
    },
    needsQuantity() {
      return this.machineHasSf(this.machine_id, "61");
    },
    needsType() {
      return this.machineHasSf(this.machine_id, "24");
    },
    cardGoodQty() {
      return this.machine.card_good_qty;
    },
    quantityInput(){
      return this.data.quantity.value;
    },
    maxScrapQty() {
      let maxScrap = this.config.max_startup_scrap
        ? (this.cardGoodQty / 100) * this.config.max_startup_scrap
        : null;
      return maxScrap != null ? maxScrap.toFixed(1) : this.cardGoodQty;
    },
    tabs() {
      let tabs = [];
      //do a check to see if we need an employee
      tabs.push({
        label: "employee",
        tlabel: "578",
        component: "employee",
        initialShow: this.needsEmployee,
        disabled: !this.needsEmployee,
        guide: "SF: 29",
        dataKey: "employee",
      });
      tabs.push({
        label: "setup_type",
        tlabel: "2749",
        action: "1051",
        component: "setup-type",
        initialShow: this.needsType,
        disabled: !this.needsType,
        guide: "SF: 24",
        dataKey: "setup_type",
      });
      tabs.push({
        label: "setup_note",
        tlabel: "2748",
        component: "note",
        initialShow: this.needsNote,
        disabled: !this.needsNote,
        guide: "SF: 23",
        dataKey: "setup_note",
      });
      tabs.push({
        label: "quantity",
        tlabel: "581",
        component: "quantity",
        initialShow: this.needsQuantity,
        disabled: !this.needsQuantity,
        guide: "SF: 61",
        dataKey: "quantity",
        sideComponent: "sf61",
      });
      return tabs;
    },
  },
  watch: {
    $route(to) {
      if (this.loaded) {
        this.loaded = false;
        let promises = [];
        Promise.all(promises).then(() => {
          this.loaded = true;
          console.log("fully loaded..");
        });
      }
    },
    cardGoodQty() {
      this.calculateLimitMessages();
    },
    quantityInput(){
      this.calculateLimitMessages();
    }
  },
};
</script>